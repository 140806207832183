/**
 * Imports
 */

// Remix imports
import { json } from '@remix-run/node';
import type { ActionFunction, LoaderFunction } from '@remix-run/node';
import { Form, Link, useLoaderData } from '@remix-run/react';

// React imports
import { useEffect } from 'react';

// ~/models import
import { getTenant, getQSTenant } from '~/models/tenant.server';
import type { Tenant } from '~/models/tenant.server';

// ~/services imports
import { getLogoutUrl } from '~/services/auth.server';

// ~/utils import
import * as gtm from '~/utils/gtm.client';
import { commitSession, getSession } from '~/utils/session.server';

// Style imports
import { ExclamationIcon } from '@heroicons/react/solid';

// Auth0 import
import { Auth0LockPasswordless } from 'auth0-lock';

// utils imports
import { v4 as uuid } from 'uuid';

/**
 * Remix action
 */
export let action: ActionFunction = ({ request }) => {
  const tenant: Tenant = getTenant(request);
  return tenant.authenticator.authenticate('auth0', request, {
    successRedirect: '/qs/search',
    failureRedirect: '/qs/auth/login'
  });
};

/**
 * Remix loader
 */
type LoaderData = {
  login: any;
  error: any;
  // tenant: Tenant
  tenantId: string;
  tenantLogo: string;
  tenantName: string;
  tenantOrgId: string;
  tenantOrgUrl: string;
  tenantColor: string;
  userSessionId: string | undefined;
  passwordlessOptions: any;
};
export const loader: LoaderFunction = async ({ request }) => {
  const tenant: Tenant = getTenant(request);

  /**
   * If request contains invitation query parameter, attempt invite authentication.
   */
  const url = new URL(request.url);
  if (url.searchParams.has('invitation')) {
    console.log('Function 2 was called');
    return tenant.authenticator.authenticate('auth0', request, {
      successRedirect: '/qs/search',
      failureRedirect: '/qs/auth/login'
    });
  }

  /**
   * Session
   */
  const session = await getSession(request.headers.get('Cookie'));
  const login = session.get('login');
  const error =
  session.get('error') || (
  url.searchParams.has('error') ?
  {
    error: url.searchParams.get('error'),
    error_description: url.searchParams.get('error_description')
  } :
  null);

  /**
   * If user Auth0 authenticated but not Circle authenticated, message error.
   */
  // if (login && login.auth0 && !login.circle) {
  //   console.log('Function 3 was called')
  //   const redirectUrl = new URL(await getLogoutUrl(tenant, error))
  //   return await tenant.authenticator.logout(request, {
  //     redirectTo: redirectUrl.toString(),
  //   })
  // }

  /**
   * User
   */
  const userSession = await tenant.authenticator.isAuthenticated(request);

  /**
   * OTP
   */
  const state = uuid();
  session.set('oauth2:state', state);

  const passwordlessOptions = tenant.passwordless && {
    clientId: tenant.authConfig.clientID,
    domain: tenant.authConfig.domain,
    allowedConnections: ['email'],
    passwordlessMethod: 'code',
    auth: {
      redirectUrl: tenant.authConfig.callbackURL,
      responseType: 'code',
      params: {
        scope: 'openid profile email offline_access',
        state
      }
    },
    theme: {
      logo: tenant.logo,
      primaryColor: tenant.color
    },
    languageDictionary: {
      title: 'Passwordless Email Login',
      passwordlessEmailInstructions: 'Enter your email to sign in.',
      signUpTerms: ''
    }
  };

  return json<LoaderData>(
    {
      login,
      error,
      tenantId: tenant.id,
      tenantLogo: tenant.logo,
      tenantName: tenant.name,
      tenantOrgId: tenant.orgId,
      tenantOrgUrl: tenant.orgUrl,
      tenantColor: tenant.color,
      userSessionId: userSession?.id,
      passwordlessOptions
    },
    {
      headers: {
        'Set-Cookie': await commitSession(session)
      }
    }
  );
};

export default function Login() {
  const {
    login,
    error,
    tenantId,
    tenantLogo,
    tenantName,
    tenantOrgId,
    tenantOrgUrl,
    tenantColor,
    userSessionId,
    passwordlessOptions
  } = useLoaderData<LoaderData>();

  /**
   *
   */
  function canTenantCreateAccount() {
    return ['ncba', 'pba', 'trgroup', 'mba'].includes(tenantId);
  }

  function isTenantACU() {
    return tenantId === 'trgroup' || tenantId === 'ccua';
  }

  function isTenantCCUA() {
    return tenantId === 'ccua';
  }

  /**
   * Checks for failed login and pushes gtm event.
   */
  useEffect(() => {
    // TODO: [BUG] This event executes multiple times until browser cookie syncs.
    // Alternative solution is to use query parameters.
    if (login && (!login.auth0 || !login.circle)) {
      gtm.login(userSessionId, tenantOrgId, !!login.auth0, !!login.circle);
    }
  });

  /**
   * Show Auth0 Lock Passwordless Modal
   */
  const showAuth0LockPasswordless = () => {
    new Auth0LockPasswordless(
      passwordlessOptions.clientId,
      passwordlessOptions.domain,
      passwordlessOptions
    ).show();
  };
  useEffect(() => {
    if (passwordlessOptions) showAuth0LockPasswordless();
  });

  return (
    <>
      {/* CSS build hack to load tenant colors.
        * Remix does not interpret interpolation when building output css (i.e. `text-${blue-variable}-600`).
        * TODO: refactor into a utility or figure out how to compile all *-colors-* in app.css.
        */}
      <div
        className="hidden
        bg-red-100 bg-red-600 bg-teal-100 bg-teal-600 bg-blue-100 bg-blue-600 bg-stone-100
        bg-stone-600 text-red-600 text-red-700 text-teal-600 text-teal-700 text-blue-600 text-blue-700
        text-stone-600 text-stone-700 hover:bg-red-200 hover:bg-red-700 hover:bg-teal-200 hover:bg-teal-700 hover:bg-blue-200
        hover:bg-blue-700 hover:bg-stone-200 hover:bg-stone-700 focus:ring-red-500 focus:ring-teal-500 focus:ring-blue-500 focus:ring-stone-500
      " />







      {/* Login page */}
      <div className="relative overflow-hidden">
        <div
          className="hidden sm:absolute sm:inset-y-0 sm:block sm:h-full sm:w-full"
          aria-hidden="true">

          <div className="relative mx-auto h-full max-w-7xl">
            <svg
              className="absolute right-full translate-y-1/4 translate-x-1/4 transform lg:translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784">

              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">

                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor" />

                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />

            </svg>
            <svg
              className="absolute left-full -translate-y-3/4 -translate-x-1/4 transform md:-translate-y-1/2 lg:-translate-x-1/2"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784">

              <defs>
                <pattern
                  id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse">

                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor" />

                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)" />

            </svg>
          </div>
        </div>
        <div className="relative pt-6 pb-16 sm:pb-24">
          <main className="mx-auto mt-16 max-w-7xl px-4 sm:mt-24">
            <div className="min-h-full bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
              <div className="mx-auto max-w-max">
                <>
                  {error ?
                  <div className="mb-12 rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <ExclamationIcon
                          className="h-5 w-5 text-red-400"
                          aria-hidden="true" />

                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            Error: {error.error}
                            {': '}
                            <span className="text-sm font-normal text-red-700">
                              {error.error_description}
                            </span>
                          </h3>
                          <p className="mt-2 text-sm text-red-700">
                            Please contact support@bankabledomain.com for
                            further assistance.
                          </p>
                        </div>
                      </div>
                    </div> :
                  null}
                </>
                <main className="sm:flex">
                  <div className="aspect-w-3 aspect-h-2 basis-1/3">
                    {tenantLogo ?
                    <img
                      src={tenantLogo}
                      alt={tenantName}
                      className="mb-12" /> :


                    <p
                      className={`text-${tenantColor}-600 text-4xl font-extrabold sm:text-5xl`}>

                        {tenantName}
                      </p>}

                  </div>
                  <div className="basis-2/3 sm:ml-6">
                    <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                      <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        {isTenantCCUA() ?
                        'Get Started - QS' :
                        'Log In / Get Started - QS'}
                      </h1>
                      <div className="text-base text-gray-500">
                        {canTenantCreateAccount() &&
                        <div className="mt-6 border-l-4 border-yellow-400 bg-yellow-50 p-4">
                            <div className="flex">
                              <div className="flex-shrink-0">
                                <ExclamationIcon
                                className="h-5 w-5 text-yellow-400"
                                aria-hidden="true" />

                              </div>
                              <div className="ml-3">
                                <p className="text-sm text-yellow-700">
                                  If you are not an employee of a{' '}
                                  {isTenantACU() ?
                                'credit union' :
                                'financial institution'}{' '}
                                  affiliated with{' '}
                                  <a
                                  href={tenantOrgUrl}
                                  className="font-medium text-yellow-700 underline hover:text-yellow-600">

                                    {tenantName}
                                  </a>
                                  , you can join Bankable Fintech's Community,
                                  by{' '}
                                  <a
                                  href="https://app.bankabledomain.com/"
                                  className="font-medium text-yellow-700 underline hover:text-yellow-600">

                                    clicking here
                                  </a>
                                  .
                                </p>
                              </div>
                            </div>
                          </div>}

                        <p className="mt-6">
                          <b>Already have an account?</b> Welcome back, please
                          click "Log in".
                        </p>
                        <p className="mt-2">
                          {isTenantCCUA() ?
                          <>
                              <b>Not sure you have an account?</b>{' '}
                              <span>
                                Try logging in with your email below or contact
                                amorin@ccua.org.
                              </span>
                            </> :

                          <>
                              <b>First time here?</b>{' '}
                              {canTenantCreateAccount() ?
                            <span>
                                  Welcome {tenantName}-Affiliated Employees.
                                  Please click "First-time User Registration"
                                  below, to get started. It is quick, secure and
                                  there is no cost.
                                </span> :

                            <span>
                                  Please click "Request an account", and connect
                                  with us.
                                </span>}

                            </>}

                        </p>
                        {!passwordlessOptions &&
                        <p className="mt-2">
                            <b>Forgot your login password or username?</b>{' '}
                            Please click "Log in", then "Forgot password?".
                          </p>}

                      </div>
                    </div>
                    <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                      {passwordlessOptions ?
                      <button
                        className={`bg-${tenantColor}-600 hover:bg-${tenantColor}-700 focus:ring-${tenantColor}-500
                        inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white
                        shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}
                        onClick={showAuth0LockPasswordless}>

                          Log in
                        </button> :

                      <Form
                        action={`/qs/auth/login?organization=${tenantOrgId}`}
                        method="post"
                        className={`bg-${tenantColor}-600 hover:bg-${tenantColor}-700 focus:ring-${tenantColor}-500
                            inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white
                            shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2`}>

                          <button>Log in</button>
                        </Form>}

                      {!isTenantCCUA() &&
                      <Link
                        to={
                        canTenantCreateAccount() ?
                        '/qs/auth/invite' :
                        '/qs/auth/connect'}

                        className={`text-${tenantColor}-700 bg-${tenantColor}-100 hover:bg-${tenantColor}-200 focus:ring-${tenantColor}-500
                          inline-flex items-center rounded-md border border-transparent px-4 py-2 text-sm font-medium
                          focus:outline-none focus:ring-2 focus:ring-offset-2`}>

                          {canTenantCreateAccount() ?
                        'First-time User Registration' :
                        'Request an account'}
                        </Link>}

                    </div>
                  </div>
                </main>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>);

}